<template>
  <v-container class="d-flex flex-wrap">
    <Objekt class="ma-2"></Objekt>
    <Parteien class="ma-2"></Parteien>
  </v-container>
</template>

<script>
import Parteien from "../components/finanzierendeParteien.vue";
import Objekt from "../components/Objekt.vue";
export default {
  components: {
    Parteien,
    Objekt,
  },
};
</script>

<script setup></script>
