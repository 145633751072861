import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueCookies from 'vue3-cookies'
import { loadFonts } from './plugins/webfontloader'
import "@mdi/font/css/materialdesignicons.css";
loadFonts()
// Tell Vue to use the plugin

createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .use(VueCookies)
  .mount('#app')
