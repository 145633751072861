<template>
  <v-app>
    <v-main>
      <v-app-bar title="Finanzierungsrechner"><v-btn :color="edit_mode ? 'green' : 'grey'" icon="mdi-pencil" @click="toggle_edit_mode"></v-btn>
        <!-- <v-btn icon="mdi-content-save" @click="this.$store.dispatch('save_state', )"></v-btn>
        <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn icon="mdi-dots-vertical" variant="text" v-bind="props"></v-btn>
            </template>

            <v-list>
              <v-list-item
              >
                <v-list-item-title>nix</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          -->
      </v-app-bar>
        <router-view />

    </v-main>
  </v-app>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";

export default {
  name: "App",
  methods: {
    ...mapMutations(["partei_erstellen","toggle_edit_mode"]),
  },
  computed: {
    ...mapGetters(["parteien", "edit_mode"]),
  },
  data: () => ({
    //
  }),
  mounted () {
  }
};
</script>
